.App {
	text-align: center;
}

.App-logo {
	height: 40vmin;
	pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
	.App-logo {
		animation: App-logo-spin infinite 20s linear;
	}
}

.App-header {
	background-color: #282c34;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);
	color: white;
}

.App-link {
	color: #61dafb;
}

@keyframes App-logo-spin {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}

.skeleton-loading {
	background-color: rgba(255, 253, 247, 0.25);
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
}

.inset-edges {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 10;
	box-shadow: 0 0 16px 16px #fffdf7 inset;
}

@keyframes pulse {
	50% {
		opacity: 0.2;
	}
}

.MuiLoadingButton-loadingIndicator {
	color: white !important;
}

.gradient {
	position: relative;
}

.gradient::after {
	position: absolute;
	content: "";
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	background-image: linear-gradient(
		to top,
		hsla(0, 0%, 0%, 0.57) 0%,
		hsla(0, 0%, 0%, 0.522) 6.8%,
		hsla(0, 0%, 0%, 0.475) 12.5%,
		hsla(0, 0%, 0%, 0.429) 17.3%,
		hsla(0, 0%, 0%, 0.384) 21.6%,
		hsla(0, 0%, 0%, 0.34) 25.5%,
		hsla(0, 0%, 0%, 0.297) 29.3%,
		hsla(0, 0%, 0%, 0.256) 33.3%,
		hsla(0, 0%, 0%, 0.216) 37.6%,
		hsla(0, 0%, 0%, 0.178) 42.6%,
		hsla(0, 0%, 0%, 0.143) 48.5%,
		hsla(0, 0%, 0%, 0.109) 55.6%,
		hsla(0, 0%, 0%, 0.078) 64%,
		hsla(0, 0%, 0%, 0.049) 74%,
		hsla(0, 0%, 0%, 0.023) 85.9%,
		hsla(0, 0%, 0%, 0) 100%
	);
	background-image: -moz-linear-gradient(
		to top,
		hsla(0, 0%, 0%, 0.57) 0%,
		hsla(0, 0%, 0%, 0.522) 6.8%,
		hsla(0, 0%, 0%, 0.475) 12.5%,
		hsla(0, 0%, 0%, 0.429) 17.3%,
		hsla(0, 0%, 0%, 0.384) 21.6%,
		hsla(0, 0%, 0%, 0.34) 25.5%,
		hsla(0, 0%, 0%, 0.297) 29.3%,
		hsla(0, 0%, 0%, 0.256) 33.3%,
		hsla(0, 0%, 0%, 0.216) 37.6%,
		hsla(0, 0%, 0%, 0.178) 42.6%,
		hsla(0, 0%, 0%, 0.143) 48.5%,
		hsla(0, 0%, 0%, 0.109) 55.6%,
		hsla(0, 0%, 0%, 0.078) 64%,
		hsla(0, 0%, 0%, 0.049) 74%,
		hsla(0, 0%, 0%, 0.023) 85.9%,
		hsla(0, 0%, 0%, 0) 100%
	);
	background-image: -webkit-linear-gradient(
		to top,
		hsla(0, 0%, 0%, 0.57) 0%,
		hsla(0, 0%, 0%, 0.522) 6.8%,
		hsla(0, 0%, 0%, 0.475) 12.5%,
		hsla(0, 0%, 0%, 0.429) 17.3%,
		hsla(0, 0%, 0%, 0.384) 21.6%,
		hsla(0, 0%, 0%, 0.34) 25.5%,
		hsla(0, 0%, 0%, 0.297) 29.3%,
		hsla(0, 0%, 0%, 0.256) 33.3%,
		hsla(0, 0%, 0%, 0.216) 37.6%,
		hsla(0, 0%, 0%, 0.178) 42.6%,
		hsla(0, 0%, 0%, 0.143) 48.5%,
		hsla(0, 0%, 0%, 0.109) 55.6%,
		hsla(0, 0%, 0%, 0.078) 64%,
		hsla(0, 0%, 0%, 0.049) 74%,
		hsla(0, 0%, 0%, 0.023) 85.9%,
		hsla(0, 0%, 0%, 0) 100%
	);
	background-image: -o-linear-gradient(
		to top,
		hsla(0, 0%, 0%, 0.57) 0%,
		hsla(0, 0%, 0%, 0.522) 6.8%,
		hsla(0, 0%, 0%, 0.475) 12.5%,
		hsla(0, 0%, 0%, 0.429) 17.3%,
		hsla(0, 0%, 0%, 0.384) 21.6%,
		hsla(0, 0%, 0%, 0.34) 25.5%,
		hsla(0, 0%, 0%, 0.297) 29.3%,
		hsla(0, 0%, 0%, 0.256) 33.3%,
		hsla(0, 0%, 0%, 0.216) 37.6%,
		hsla(0, 0%, 0%, 0.178) 42.6%,
		hsla(0, 0%, 0%, 0.143) 48.5%,
		hsla(0, 0%, 0%, 0.109) 55.6%,
		hsla(0, 0%, 0%, 0.078) 64%,
		hsla(0, 0%, 0%, 0.049) 74%,
		hsla(0, 0%, 0%, 0.023) 85.9%,
		hsla(0, 0%, 0%, 0) 100%
	);
}

.blanco-hover {
	background-color: #1947921b !important;
}

.reposado-hover {
	background-color: #6c0d0f1b !important;
}

.cristalino-hover {
	background-color: #0204061b !important;
}

.szh-menu {
	background-color: rgba(255, 253, 247, 0.8) !important;
	backdrop-filter: blur(20px) !important;
	-webkit-backdrop-filter: blur(20px) !important;
	transition: all 0.2s ease-in;
}

.szh-menu.scroll {
	background-color: #fffdf7 !important;
	transition: all 0.2s ease-in;
}

.szh-menu__arrow {
	border: none !important;
	background-color: #012233ff !important;
	backdrop-filter: blur(20px) !important;
	-webkit-backdrop-filter: blur(20px) !important;
	transition: all 0.2s ease-in;
}

.szh-menu__arrow.scroll {
	border: none !important;
	background-color: #fffdf7 !important;
	transition: all 0.2s ease-in;
}

/* cabin-regular - latin */
@font-face {
	font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
	font-family: "Cabin";
	font-style: normal;
	font-weight: 400;
	src: url("./fonts/cabin-v26-latin-regular.woff2") format("woff2"); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* cabin-500 - latin */
@font-face {
	font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
	font-family: "Cabin";
	font-style: normal;
	font-weight: 500;
	src: url("./fonts/cabin-v26-latin-500.woff2") format("woff2"); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* cabin-600 - latin */
@font-face {
	font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
	font-family: "Cabin";
	font-style: normal;
	font-weight: 600;
	src: url("./fonts/cabin-v26-latin-600.woff2") format("woff2"); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* cabin-700 - latin */
@font-face {
	font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
	font-family: "Cabin";
	font-style: normal;
	font-weight: 700;
	src: url("./fonts/cabin-v26-latin-700.woff2") format("woff2"); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}

/* roboto-100 - latin */
@font-face {
	font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
	font-family: "Roboto";
	font-style: normal;
	font-weight: 100;
	src: url("./fonts/roboto-v30-latin-100.woff2") format("woff2"); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* roboto-300 - latin */
@font-face {
	font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
	font-family: "Roboto";
	font-style: normal;
	font-weight: 300;
	src: url("./fonts/roboto-v30-latin-300.woff2") format("woff2"); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* roboto-regular - latin */
@font-face {
	font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
	font-family: "Roboto";
	font-style: normal;
	font-weight: 400;
	src: url("./fonts/roboto-v30-latin-regular.woff2") format("woff2"); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* roboto-500 - latin */
@font-face {
	font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
	font-family: "Roboto";
	font-style: normal;
	font-weight: 500;
	src: url("./fonts/roboto-v30-latin-500.woff2") format("woff2"); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* roboto-700 - latin */
@font-face {
	font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
	font-family: "Roboto";
	font-style: normal;
	font-weight: 700;
	src: url("./fonts/roboto-v30-latin-700.woff2") format("woff2"); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* roboto-900 - latin */
@font-face {
	font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
	font-family: "Roboto";
	font-style: normal;
	font-weight: 900;
	src: url("./fonts/roboto-v30-latin-900.woff2") format("woff2"); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}

/* dawning-of-a-new-day-regular - latin */
@font-face {
	font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
	font-family: "Dawning of a New Day";
	font-style: normal;
	font-weight: 400;
	src: url("./fonts/dawning-of-a-new-day-v16-latin-regular.woff2") format("woff2"); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
